.privacy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 48px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .privacy-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  h2, h3 {
    color: #555;
  }
  
  p, ul {
    line-height: 1.6;
    color: #333;
  }
  
  ul {
    padding-left: 20px;
  }
  
  ul li {
    margin-bottom: 10px;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  