html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Oculta completamente la barra de scroll */
}

body {
  overflow-y: scroll; /* Permite el scroll sin mostrar la barra */
  -ms-overflow-style: none;  /* Para Internet Explorer y Edge */
  scrollbar-width: none;  /* Para Firefox */
}

body::-webkit-scrollbar {
  display: none; /* Para Chrome, Safari y Opera */
}
